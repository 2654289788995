<template>
  <v-container
    id="login"
    class="fill-height"
    tag="section"
    :style="$vuetify.breakpoint.mdAndUp ? 'width: 25%' : 'width: 75%'"
  >
    <v-row justify="center">
      <v-slide-y-transition appear>
        <div style="width: 30em; text-align: center">
          <img
            src="../../assets/logo.png"
            width="150"
            v-if="$vuetify.breakpoint.lgAndDown"
          />
          <img
            src="../../assets/logo.png"
            width="250"
            v-if="!$vuetify.breakpoint.lgAndDown"
          />
          <form
            action
            data-vv-scope="login-form"
            @submit.prevent="validateForm('login-form')"
          >
            <v-row>
              <v-col>
                <v-text-field
                  v-model="email"
                  data-vv-validate-on="blur"
                  v-validate="'required|email'"
                  type="email"
                  data-vv-name="email"
                  color="primary"
                  :label="$t('email')"
                  outlined
                  class="color v-text-field rounded-lg"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                /> </v-col></v-row
            ><v-row style="margin-top: -30px">
              <v-col>
                <v-text-field
                  v-model="password"
                  data-vv-validate-on="blur"
                  v-validate="'required|min:2'"
                  :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="show = !show"
                  :type="show ? 'text' : 'password'"
                  data-vv-name="password"
                  color="primary"
                  :label="$t('password')"
                  outlined
                  class="color v-text-field rounded-lg"
                /> </v-col
            ></v-row>
            <v-row style="margin-top: -35px">
              <v-col>
                <a href="/?#/auth/password_forgot">{{
                  $t("forgot_password")
                }}</a>
              </v-col></v-row
            >
            <base-material-alert
              style="
                margin-top: 10px;
                width: 140%;
                margin-left: -20%;
                padding-left: 10%;
                padding-right: 10%;
                border-radius: 10px;
              "
              v-if="$store.state.auth.errorLogin"
              color="login_error"
              dark
              >{{ $t("invalid-credentials") }}
              <a
                href="/?#/auth/password_forgot"
                style="color: white; font-size: 14px"
              >
                {{ $t("forgot_password") }}
              </a>
            </base-material-alert>
            <v-row
              ><v-col>
                <v-btn
                  block
                  large
                  color="primary"
                  type="submit"
                  elevation="0"
                  style="font-size: 18px; text-transform: none"
                  >{{ $t("lets_go") }}</v-btn
                >
              </v-col></v-row
            ><!--
            <v-row
              style="margin-top: -35px"
              v-bind:style="{
                'margin-left': $vuetify.breakpoint.lgAndDown ? '5%' : '15%',
              }"
            >
              <v-spacer />
              <v-col cols="12">
                <v-checkbox
                  v-model="maintainSession"
                  :label="$t('maintain_session')"
                  class="color"
                  off-icon
                  ><template v-slot:prepend>
                    <v-icon
                      style="
                        position: absolute;
                        padding-left: 10.7px;
                        padding-top: 2.7px;
                        color: var(--v-primary-base);
                      "
                      >mdi-checkbox-blank-outline</v-icon
                    ></template
                  ></v-checkbox
                >
                
              </v-col>
              <v-spacer />
            </v-row>-->
            <v-row align="center" style="color: var(--v-primary-base)">
              <v-divider
                style="
                  background-color: var(--v-primary-base);
                  margin-right: 10px;
                "
              ></v-divider>
              {{ $t("or_login_with") }}
              <v-divider
                style="
                  background-color: var(--v-primary-base);
                  margin-left: 10px;
                "
              ></v-divider>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  @click="gmailSingIn"
                  rounded
                  block
                  large
                  style="
                    color: var(--v-primary-base);
                    background-color: transparent;
                    border: 2px solid var(--v-primary-base);
                    font-size: 18px;
                    text-transform: none;
                  "
                >
                  <v-icon style="margin-right: 20px">$google</v-icon>Google
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              ><v-col>
                {{ $t("register_new") }}
                <a href="/?#/signup">{{ $t("register") }}</a></v-col
              ></v-row
            >
          </form>
        </div>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import MaterialAlert from "@/components/base/MaterialAlert";
import { mapActions } from "vuex";

export default {
  name: "PagesLogin",

  $_veeValidate: {
    validator: "new",
  },

  components: {
    MaterialAlert,
    PagesBtn: () => import("./components/Btn"),
  },

  data: () => ({
    email: "",
    password: "",
    pjson: {},
    show: false,
  }),

  mounted() {
    //console.log("entramos al login");
    this.$store.commit("auth/RESET_INVALID_CREDENTIALS", null, {
      root: true,
    });
  },

  methods: {
    ...mapActions("auth", ["authenticate", "authenticateOauth"]),
    async validateForm(scope) {
      //console.log(this.$route.params.inscription);

      const result = await this.$validator.validateAll(scope);
      if (result) {
        this.$store.commit("auth/RESET_INVALID_CREDENTIALS");
        await this.$store.dispatch("auth/authenticate", {
          email: this.email,
          password: this.password,
        });
        this.postLogin();
      }
    },
    async gmailSingIn() {
      this.$store.commit("auth/RESET_INVALID_CREDENTIALS");

      let GoogleUser = await this.$gAuth.signIn();

      await this.$store.dispatch("auth/authenticateOauth", {
        type: "google",
        token_id: GoogleUser.getId(),
      });
      this.postLogin();

      /*
      //console.log("%c%s", "color: #00a3cc", "Auth", authCode);

      //console.log("%c%s", "color: #aa00ff", authCode.wc.access_token);
      */
    },
    async postLogin() {
      if (!this.$store.state.auth.errorLogin) {
        if (this.$route.params.inscription) {
          this.$router.back();
        } else {
          await this.$router.push("/");
        }
      } else {
        this.$store.commit(
          "app/SET_PROCESSING",
          {
            show: false,
            message: null,
          },
          {
            root: true,
          }
        );
      }
    },
  },
};
</script>
<style lang="sass">
#login
  .color .v-label
    color: var(--v-primary-base) !important
  input
    padding-top: 0
    padding-bottom: 0
    font-size: 16px !important
    margin-top: 6px
    height: 20px !important

  fieldset
    height: 100%

.v-text-field
  border-radius: 15px
  display: flex
  justify-content: center
  align-items: center

@media (max-width: 400px)

  #login
    .v-input__slot
      padding-bottom: 10px !important
    .v-text-field input
      width: 200px
      height: 15px
  .v-btn:not(.v-btn--round).v-size--large
    height: 40px
  .v-text-field
    border-radius: 15px
    display: flex
    justify-content: center
    align-items: center
  fieldset
    widht: 100% !important
    height: 100%
</style>
